import React, { Fragment } from 'react';
import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'
import Column from './column'
import './hero17.css'


const Hero17 = (props) => {

  return (
    <div id={props.hero17Id} className="hero17-header78">
      <Column
        action1={
          <Fragment>
            <span className="hero17-text1">
              <span>Bekleme Listesine Katıl</span>
              <br></br>
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="hero17-text4">
              Reklamlar aracılığıyla kazanmanın geleceğini inşa ediyoruz. Erken
              erişim için kaydolun.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="hero17-text5">
              <span>Sadece İzle ve Kazan!</span>
              <br></br>
            </span>
          </Fragment>
        }
        rootClassName="columnroot-class-name"
        textinputPlaceholder="Email"
      ></Column>
      <div className="hero17-content">
        <div className="hero17-row-container1 thq-mask-image-horizontal thq-animated-group-container-horizontal">
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="hero17-placeholder-image10 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="hero17-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="hero17-placeholder-image12 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="hero17-placeholder-image13 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="hero17-placeholder-image14 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image6Alt}
              src={props.image6Src}
              className="hero17-placeholder-image15 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="hero17-placeholder-image16 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="hero17-placeholder-image17 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="hero17-placeholder-image18 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="hero17-placeholder-image19 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="hero17-placeholder-image20 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1534312527009-56c7016453e6?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="hero17-placeholder-image21 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
        </div>
        <div className="hero17-row-container2 thq-mask-image-horizontal thq-animated-group-container-horizontal">
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image8Alt}
              src={props.image8Src}
              className="hero17-placeholder-image23 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image9Alt}
              src={props.image9Src}
              className="hero17-placeholder-image24 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="hero17-placeholder-image25 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="hero17-placeholder-image26 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image12Alt}
              src={props.image12Src}
              className="hero17-placeholder-image27 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="hero17-placeholder-image28 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image8Alt}
              src={props.image8Src}
              className="hero17-placeholder-image29 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image9Alt}
              src={props.image9Src}
              className="hero17-placeholder-image30 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="hero17-placeholder-image31 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="hero17-placeholder-image32 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1568214379698-8aeb8c6c6ac8?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxncmFmaWN8ZW58MHx8fHwxNzE1Nzk0OTk5fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="hero17-placeholder-image33 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="hero17-container2">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

Hero17.defaultProps = {
  image6Src:  '/images/1.jpg',
  image8Alt:  '8',
  image5Alt:  '5',
  image3Src:  '/images/2.jpg',
  image11Src: '/images/3.jpg',
  image11Alt: '11',
  image7Src:  '/images/4.jpg',
  image1Src:  '/images/5.jpg',
  image9Src:  '/images/6.jpg',
  image12Src: '/images/7.jpg',
  image3Alt:  '3',
  hero17Id:   '',
  image10Src: '/images/8.jpg',
  image6Alt:  '6',
  image2Src:  '/images/9.jpg',
  image8Src:  '/images/10.jpg',
  image1Alt:  '1',
  image4Alt:  '4',
  image5Src:  '/images/11.jpg',
  image10Alt: '10',
  image9Alt:  '9',
  image4Src:  '/images/12.jpg',
  image12Alt: '12',
  image7Alt:  '7',
  image2Alt:  '2',
}

Hero17.propTypes = {
  image6Src: PropTypes.string,
  image8Alt: PropTypes.string,
  image5Alt: PropTypes.string,
  image3Src: PropTypes.string,
  image11Src: PropTypes.string,
  image11Alt: PropTypes.string,
  image7Src: PropTypes.string,
  image1Src: PropTypes.string,
  image9Src: PropTypes.string,
  image12Src: PropTypes.string,
  image3Alt: PropTypes.string,
  hero17Id: PropTypes.string,
  image10Src: PropTypes.string,
  image6Alt: PropTypes.string,
  image2Src: PropTypes.string,
  image8Src: PropTypes.string,
  image1Alt: PropTypes.string,
  image4Alt: PropTypes.string,
  image5Src: PropTypes.string,
  image10Alt: PropTypes.string,
  image9Alt: PropTypes.string,
  image4Src: PropTypes.string,
  image12Alt: PropTypes.string,
  image7Alt: PropTypes.string,
  image2Alt: PropTypes.string,
}

export default Hero17

import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Your Firebase config from the console
const firebaseConfig = {
  apiKey: "AIzaSyA8SlFPUYbf4q3cKeLVW6KL78uNBS1OtBs",
  authDomain: "kazanco-website-c5e69.firebaseapp.com",
  projectId: "kazanco-website-c5e69",
  storageBucket: "kazanco-website-c5e69.firebasestorage.app",
  messagingSenderId: "1008352878473",
  appId: "1:1008352878473:web:7e1028253e9f64921a4b58",
  measurementId: "G-HFRH871P1V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, addDoc};

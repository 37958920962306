import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Hero17 from '../components/hero17'
import Contact10 from '../components/contact10'
import Steps2 from '../components/steps2'
import Footer4 from '../components/footer4'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Kazanco</title>
        <meta
          name="description"
          content="Kazanco - Sadece İzle ve Kazan!\n\nReklamlar aracılığıyla kazanmanın geleceğini inşa ediyoruz."
        />
        <meta property="og:title" content="Kazanco" />
        <meta
          property="og:description"
          content="Kazanco - Sadece İzle ve Kazan!\n\nReklamlar aracılığıyla kazanmanın geleceğini inşa ediyoruz."
        />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="home-text10">Hakkımızda</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text11">Özellikler</span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="home-text14">Hakkımızda</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="home-text15">Özellikler</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text19">Erken Erişim</span>
          </Fragment>
        }
        logoSrc="/images/favicon.png"
        link1Url="#Contact10"
        link2Url="#Steps2"
        page1Description={
          <Fragment>
            <span className="home-text20">
              Hakkımızda
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="home-text21">
              Özellikler
            </span>
          </Fragment>
        }
      ></Navbar8>
      <Hero17 hero17Id="Hero17"></Hero17>
      <Contact10
        content1={
          <Fragment>
            <span className="home-text24">
              Kazanco olarak, herkesin kolay bir şekilde kazanç sağlaması
              gerektiğine inanıyoruz. Felsefemiz basit: Kazanmayı hem
              erişilebilir hem de keyifli hale getiriyoruz. Mobil uygulamamızla,
              reklam izleyerek puanlar kazanabilir, bu küçük adımları gerçek
              paraya dönüştürebilirsiniz. Kazanç dünyamıza katılın ve kendi
              şartlarınızla kazanmaya başlayın!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text25">Hakkımızda</span>
          </Fragment>
        }
        contact10Id="Contact10"
      ></Contact10>
      <Steps2
        steps2Id="Steps2"
        step1Title={
          <Fragment>
            <span className="home-text26">Puan Tabanlı Sistem</span>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <span className="home-text27">Düşük Ödeme Limiti</span>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <span className="home-text28">Gizli Maliyet Yok</span>
          </Fragment>
        }
        step4Title={
          <Fragment>
            <span className="home-text29">Tavsiye Programı</span>
          </Fragment>
        }
        step1Description={
          <Fragment>
            <span className="home-text30">
              <span>
                İzlenen her reklam kullanıcılara puan kazandırır, bu puanlar
                biriktirilebilir ve gerçek paraya dönüştürülebilir.
              </span>
              <br></br>
            </span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text33">
              Kullanıcıların puanlarını daha erken kullanmalarını sağlayarak
              ödül tabanlı uygulamalardaki en önemli sorunlardan birini
              çözüyoruz.
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text34">
              Abonelik veya ön yatırım gerektiren diğer platformların aksine, bu
              uygulamanın hiçbir gizli maliyeti yoktur.
            </span>
          </Fragment>
        }
        step4Description={
          <Fragment>
            <span className="home-text35">
              Kullanıcılar sadakat programları aracılığıyla ve arkadaşlarını
              yönlendirerek ekstra puanlar kazanabilir.
            </span>
          </Fragment>
        }
      ></Steps2>
      <Footer4
      
        termsLink={
          <Fragment>
            <a href={'/terms'}>
            <span className="home-text36">Koşullar ve Şartlar</span>
            </a>
          </Fragment>
        }
      
        privacyLink={
          <Fragment>
            <a href={'/privacy'}>
            <span className="home-text37">Gizlilik Politikası</span>
            </a>
            </Fragment>
        }
      ></Footer4>
    </div>
  )
}

export default Home

import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './steps2.css'

const Steps2 = (props) => {
  return (
    <div id={props.steps2Id} className="steps2-container1 thq-section-padding">
      <div className="steps2-max-width thq-section-max-width">
        <div className="steps2-container2">
          <div className="steps2-container3 thq-card">
            <h2 className="thq-heading-2">
              {props.step1Title ?? (
                <Fragment>
                  <span className="steps2-text24">Join the Waitlist</span>
                </Fragment>
              )}
            </h2>
            <span className="steps2-text11 thq-body-small">
              {props.step1Description ?? (
                <Fragment>
                  <span className="steps2-text22">
                    Sign up to join the waitlist and be the first to know when
                    the app is available for download.
                  </span>
                </Fragment>
              )}
            </span>
            <label className="steps2-text12 thq-heading-3">01</label>
          </div>
          <div className="steps2-container4 thq-card">
            <h2 className="thq-heading-2">
              {props.step2Title ?? (
                <Fragment>
                  <span className="steps2-text26">Watch Ads</span>
                </Fragment>
              )}
            </h2>
            <span className="steps2-text14 thq-body-small">
              {props.step2Description ?? (
                <Fragment>
                  <span className="steps2-text28">
                    Watch ads within the app to earn points that can be later
                    converted into real money.
                  </span>
                </Fragment>
              )}
            </span>
            <label className="steps2-text15 thq-heading-3">02</label>
          </div>
          <div className="steps2-container5 thq-card">
            <h2 className="thq-heading-2">
              {props.step3Title ?? (
                <Fragment>
                  <span className="steps2-text27">Earn Points</span>
                </Fragment>
              )}
            </h2>
            <span className="steps2-text17 thq-body-small">
              {props.step3Description ?? (
                <Fragment>
                  <span className="steps2-text23">
                    Accumulate points by completing tasks and engaging with the
                    app to increase your earnings.
                  </span>
                </Fragment>
              )}
            </span>
            <label className="steps2-text18 thq-heading-3">03</label>
          </div>
          <div className="steps2-container6 thq-card">
            <h2 className="thq-heading-2">
              {props.step4Title ?? (
                <Fragment>
                  <span className="steps2-text25">Convert Points to Cash</span>
                </Fragment>
              )}
            </h2>
            <span className="steps2-text20 thq-body-small">
              {props.step4Description ?? (
                <Fragment>
                  <span className="steps2-text29">
                    Exchange your earned points for real money or rewards once
                    you reach a certain threshold.
                  </span>
                </Fragment>
              )}
            </span>
            <label className="steps2-text21 thq-heading-3">04</label>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps2.defaultProps = {
  step1Description: undefined,
  step3Description: undefined,
  step1Title: undefined,
  steps2Id: '',
  step4Title: undefined,
  step2Title: undefined,
  step3Title: undefined,
  step2Description: undefined,
  step4Description: undefined,
}

Steps2.propTypes = {
  step1Description: PropTypes.element,
  step3Description: PropTypes.element,
  step1Title: PropTypes.element,
  steps2Id: PropTypes.string,
  step4Title: PropTypes.element,
  step2Title: PropTypes.element,
  step3Title: PropTypes.element,
  step2Description: PropTypes.element,
  step4Description: PropTypes.element,
}

export default Steps2

import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import CTA1 from '../components/cta1'
import './terms.css'

const Terms = (props) => {
  return (
    <div className="terms-container">
      <Helmet>
        <title>Terms - Kazanco</title>
        <meta
          name="description"
          content="Kazanco - Sadece İzle ve Kazan!\n\nReklamlar aracılığıyla kazanmanın geleceğini inşa ediyoruz."
        />
        <meta property="og:title" content="Terms - Kazanco" />
        <meta
          property="og:description"
          content="Kazanco - Sadece İzle ve Kazan!\n\nReklamlar aracılığıyla kazanmanın geleceğini inşa ediyoruz."
        />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="terms-text100">Uygulama</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="terms-text101">Özellikler</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="terms-text102">Hakkımızda</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="terms-text103">SSS</span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="terms-text104">Uygulama</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="terms-text105">Özellikler</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="terms-text106">Hakkımızda</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="terms-text107">SSS</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="terms-text108">Erken Erişim</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="terms-text109">Erken Erişim</span>
          </Fragment>
        }
        logoSrc="/images/favicon.png"
        link1Url="#Hero17"
        link2Url="#Steps2"
        link3Url="#Contact10"
        page1Description={
          <Fragment>
            <span className="terms-text110">
              Welcome to our mobile app landing page
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="terms-text111">
              Discover the amazing features of our app
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="terms-text112">
              Watch ads and earn points to convert into real money
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="terms-text113">
              Join our waitlist to be the first to know when the app launches
            </span>
          </Fragment>
        }
      ></Navbar8>
      <CTA1
        content1={
          <Fragment>
            <span className="terms-text114">
              <span>
                Kazanco&apos;ya Hoş Geldiniz! Mobil uygulamamıza veya web
                sitemize (kazanco.me) erişerek, bu Hüküm ve Koşullara uymayı
                kabul etmiş olursunuz. Bu şartların herhangi bir bölümünü kabul
                etmiyorsanız, lütfen hizmetlerimizi kullanmayın. Bu şartlarla
                ilgili her türlü sorunuz için lütfen kazanco@gmail.com
                adresinden bizimle iletişime geçin.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text118">1. Şartların Kabul Edilmesi</span>
              <br className="terms-text119"></br>
              <span>
                Bu Şartlar ve Koşullar (“Şartlar” olarak anılacaktır) Kazanco
                mobil uygulamasına ve web sitesine erişiminiz ve kullanımınız
                için geçerlidir. Bir hesap oluşturarak, Kazanco&apos;ya erişerek
                veya Kazanco&apos;yu kullanarak bu Şartları kabul etmiş
                olursunuz. Kazanco, bu Şartları istediği zaman güncelleme
                hakkını saklı tutar ve bunları periyodik olarak gözden geçirmek
                sizin sorumluluğunuzdadır. Platformun kullanılmaya devam
                edilmesi, değişikliklerin kabul edildiği anlamına gelir.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text123">2. Uygunluk</span>
              <br className="terms-text124"></br>
              <span>
                Kazanco&apos;yu kullanmak için en az 18 yaşında veya yargı
                bölgenizdeki reşit olma yaşından hangisi daha yüksekse o yaşta
                olmalısınız. Uygulamayı kullanarak, bu yaş gereksinimlerini
                karşıladığınızı onaylamış olursunuz. Kazanco, bu gerekliliklere
                uymayan kullanıcıların hesaplarını feshetme hakkını saklı tutar.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text128">3. Hizmetlerin Tanımı</span>
              <br className="terms-text129"></br>
              <span>
                Kazanco, kullanıcıların reklamları izleyerek puan kazanmalarını
                sağlayan bir mobil uygulamadır. Kazanılan puanlar daha sonra bu
                Şartlarda belirtildiği gibi parasal ödüllere dönüştürülebilir.
                Kazanco, belirli bir ödeme miktarı veya sıklığını garanti etmez
                ve kendi takdirine bağlı olarak kazanç ve ödül yapısını
                değiştirme hakkını saklı tutar.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text133">4. Hesap Kaydı</span>
              <br className="terms-text134"></br>
              <span>
                Kazanco&apos;nun belirli özelliklerine erişmek için doğru ve
                eksiksiz bilgilerle bir hesap oluşturmalısınız. Hesap kimlik
                bilgilerinizin gizliliğini korumaktan ve hesabınız altında
                gerçekleşen her türlü faaliyetten siz sorumlusunuz. Yetkisiz
                erişimden şüpheleniyorsanız, lütfen derhal bizimle iletişime
                geçin.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text138">5. Puanlar ve Ödüller</span>
              <br></br>
              <span className="terms-text140">5.1 Puan Kazanma</span>
              <br className="terms-text141"></br>
              <span>
                - Kazanco uygulaması içinde reklamları izleyerek ve belirlenen
                etkinliklere katılarak puan kazanabilirsiniz.
              </span>
              <br></br>
              <span>
                - Kazanco, her etkinlik için verilen puan sayısını belirleme
                hakkını saklı tutar ve kazanç fırsatlarını ve puan değerlerini
                istediği zaman değiştirebilir.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text147">
                5.2 Puanları Paraya Dönüştürme
              </span>
              <br className="terms-text148"></br>
              <span>
                - Uygulama aracılığıyla kazanılan puanlar, Kazanco tarafından
                belirlenen oranlara ve kurallara göre parasal ödüllere
                dönüştürülebilir.
              </span>
              <br></br>
              <span>
                - Para çekme için minimum eşik, ödeme yöntemleri ve işlem
                süreleri değişebilir ve konuma veya para birimine bağlı olarak
                değişebilir.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text154">
                5.3 Puanların Süresinin Dolması
              </span>
              <br className="terms-text155"></br>
              <span>
                - Kazanco, puanlar için bir sona erme süresi belirleme hakkını
                saklı tutar ve kendi takdirine bağlı olarak, bu süre içinde
                kullanılmayan puanları geçersiz kılabilir.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text159">6. Uygulama Kullanımı</span>
              <br className="terms-text160"></br>
              <span>
                Kazanco&apos;yu yalnızca kişisel, ticari olmayan amaçlarla ve bu
                Şartların yanı sıra geçerli yasa ve yönetmeliklere uygun olarak
                kullanmayı kabul edersiniz. Yasaklanan faaliyetler aşağıdakileri
                içerir, ancak bunlarla sınırlı değildir:
              </span>
              <br></br>
              <span>
                - Puan toplamak için otomatik komut dosyaları veya botlar
                kullanmak gibi uygulama kullanım verilerini manipüle etmek veya
                tahrif etmek.
              </span>
              <br></br>
              <span>
                - Uygulamanın çalışmasını hacklemeye, tersine mühendislik
                yapmaya veya başka bir şekilde müdahale etmeye çalışmak.
              </span>
              <br></br>
              <span>
                - Puanları veya ödülleri manipüle etmek için herhangi bir hileli
                veya aldatıcı faaliyette bulunmak.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text170">7. Ödeme ve Para Çekme</span>
              <br></br>
              <span>
                - Para çekme seçenekleri ve işlem süreleri, bulunduğunuz yere ve
                ödeme yönteminize bağlı olarak değişebilir.
              </span>
              <br></br>
              <span>
                - Kazanco, para çekme işlemlerini gerçekleştirmeden önce kimlik
                doğrulaması talep etme hakkını saklı tutar.
              </span>
              <br></br>
              <span>
                - Para çekme işlemleri için ilgili tüm işlem ücretleri nihai
                ödeme tutarından düşülecektir.
              </span>
              <br></br>
              <span>
                - Kazanco, üçüncü taraf ödeme sağlayıcılarından kaynaklanan
                işlem gecikmelerinden sorumlu değildir.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text181">8. Fikri Mülkiyet Hakları</span>
              <br></br>
              <span>
                Metin, grafik, logo, simge, resim, yazılım ve diğer materyaller
                dahil ancak bunlarla sınırlı olmamak üzere Kazanco&apos;daki tüm
                içerik Kazanco&apos;ya veya lisans verenlerine aittir ve fikri
                mülkiyet yasalarıyla korunmaktadır. Kazanco&apos;dan önceden
                yazılı izin almadan Kazanco içeriğini kullanamaz, değiştiremez
                veya dağıtamazsınız.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text186">9. Hesabın Feshi</span>
              <br></br>
              <span>
                Kazanco, aşağıdaki durumlarda tamamen kendi takdirine bağlı
                olarak hesabınızı askıya alma, devre dışı bırakma veya feshetme
                hakkını saklı tutar:
              </span>
              <br></br>
              <span>
                - Bu Şartları ihlal ederseniz veya herhangi bir yasa dışı
                faaliyette bulunursanız.
              </span>
              <br></br>
              <span>
                - Hesabınızda, puan toplamak için otomasyon kullanmak veya
                verileri manipüle etmek de dahil olmak üzere hileli
                faaliyetlerden şüphelenilmesi.
              </span>
              <br></br>
              <span>
                - Hesabınızın Kazanco&apos;nun politikalarında belirtildiği gibi
                uzun bir süre aktif olmaması.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text197">
                10. Sorumluluğun Sınırlandırılması
              </span>
              <br className="terms-text198"></br>
              <span>
                Kazanco, herhangi bir garanti olmaksızın “olduğu gibi” ve
                “mevcut olduğu gibi” esasına göre sağlanmaktadır. Yasaların izin
                verdiği azami ölçüde:
              </span>
              <br></br>
              <span>
                - Kazanco, satılabilirlik, belirli bir amaca uygunluk ve ihlal
                etmeme dahil olmak üzere hizmetleriyle ilgili tüm zımni
                garantileri reddeder.
              </span>
              <br></br>
              <span>
                - Kazanco, uygulamayı kullanmanızdan veya kullanamamanızdan
                kaynaklanan herhangi bir dolaylı, arızi, sonuç olarak ortaya
                çıkan veya cezai zarardan, Kazanco bu tür zararların olasılığı
                konusunda bilgilendirilmiş olsa bile sorumlu olmayacaktır.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text206">11. Gizlilik Politikası</span>
              <br className="terms-text207"></br>
              <span>
                Gizlilik Politikamız bu Şartlara dahil edilmiştir ve
                verilerinizi nasıl topladığımızı, kullandığımızı ve koruduğumuzu
                açıklamaktadır. Bu Şartları kabul ederek,
                kazanco.me/privacy-policy adresinde bulabileceğiniz Gizlilik
                Politikamızı da kabul etmiş olursunuz.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text211">
                12. Geçerli Hukuk ve Yargı Yetkisi
              </span>
              <br className="terms-text212"></br>
              <span>
                Bu Koşullar, Türkiye yasalarına göre yönetilecek ve
                yorumlanacaktır. Bu Koşullardan kaynaklanan veya bu Koşullarla
                ilgili her türlü ihtilaf için İstanbul, Türkiye&apos;de bulunan
                mahkemelerin münhasır yargı yetkisine tabi olmayı kabul
                edersiniz.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text216">
                13. Bu Şartlarda Yapılan Değişiklikler
              </span>
              <br className="terms-text217"></br>
              <span>
                Kazanco, bu Şartları istediği zaman değiştirme veya güncelleme
                hakkını saklı tutar. Güncellenmiş Şartları kazanco.me adresinde
                yayınlayarak önemli değişiklikleri size bildireceğiz. Bu
                Şartları düzenli olarak gözden geçirmek sizin
                sorumluluğunuzdadır. Herhangi bir değişiklikten sonra
                Kazanco&apos;yu kullanmaya devam etmeniz, güncellenmiş Şartları
                kabul ettiğiniz anlamına gelir.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text221">14. Bize Ulaşın</span>
              <br className="terms-text222"></br>
              <span>
                Bu Koşullar hakkında herhangi bir sorunuz veya endişeniz varsa,
                lütfen bizimle şu adresten iletişime geçin:
              </span>
              <br></br>
              <span>Kazanco</span>
              <br></br>
              <span>E-posta: kazanco@gmail.com</span>
              <br></br>
              <span>Adres İstanbul, Türkiye</span>
              <br></br>
              <br></br>
              <span className="terms-text232">
                15.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="terms-text233">Çeşitli</span>
              <br className="terms-text234"></br>
              <span className="terms-text235">
                - Tüm Sözleşme:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Bu Koşullar ve burada atıfta bulunulan diğer politikalar,
                uygulamayı ve web sitesini kullanımınızla ilgili olarak sizinle
                Kazanco arasındaki sözleşmenin tamamını oluşturur.
              </span>
              <br></br>
              <span className="terms-text238">
                - Bölünebilirlik:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Bu Şartların herhangi bir hükmünün geçersiz veya uygulanamaz
                olduğu tespit edilirse, kalan hükümler tam olarak yürürlükte
                kalmaya devam edecektir.
              </span>
              <br></br>
              <span className="terms-text241">- Feragat:</span>
              <span>
                {' '}
                Kazanco&apos;nun bu Koşullardaki herhangi bir hakkı veya hükmü
                uygulamaması, söz konusu hak veya hükümden feragat ettiği
                anlamına gelmez.
              </span>
              <br></br>
              <br></br>
              <span className="terms-text245">
                Kazanco&apos;yu kullanarak, bu Hüküm ve Koşulları okuduğunuzu,
                anladığınızı ve bunlara bağlı kalmayı kabul ettiğinizi onaylamış
                olursunuz. 
              </span>
              <span className="terms-text246">
                Bu Şartlar ve Koşullar sayfası, hem Kazanco hem de kullanıcıları
                için şeffaflık ve yasal koruma sağlayarak kullanıcı
                yükümlülüklerini ve haklarını belirlemeyi amaçlamaktadır.
              </span>
              <br className="terms-text247"></br>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="terms-text250">Şartlar ve Koşullar</span>
          </Fragment>
        }
      ></CTA1>
    </div>
  )
}

export default Terms

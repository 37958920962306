import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import CTA1 from '../components/cta1'
import './privacy.css'

const Privacy = (props) => {
  return (
    <div className="privacy-container">
      <Helmet>
        <title>Privacy - Kazanco</title>
        <meta
          name="description"
          content="Kazanco - Sadece İzle ve Kazan!\n\nReklamlar aracılığıyla kazanmanın geleceğini inşa ediyoruz."
        />
        <meta property="og:title" content="Privacy - Kazanco" />
        <meta
          property="og:description"
          content="Kazanco - Sadece İzle ve Kazan!\n\nReklamlar aracılığıyla kazanmanın geleceğini inşa ediyoruz."
        />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="privacy-text100">Uygulama</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="privacy-text101">Özellikler</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="privacy-text102">Hakkımızda</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="privacy-text103">SSS</span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="privacy-text104">Uygulama</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="privacy-text105">Özellikler</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="privacy-text106">Hakkımızda</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="privacy-text107">SSS</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="privacy-text108">Erken Erişim</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="privacy-text109">Erken Erişim</span>
          </Fragment>
        }
        logoSrc="/images/favicon.png"
        link1Url="#Hero17"
        link2Url="#Steps2"
        link3Url="#Contact10"
        page1Description={
          <Fragment>
            <span className="privacy-text110">
              Welcome to our mobile app landing page
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="privacy-text111">
              Discover the amazing features of our app
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="privacy-text112">
              Watch ads and earn points to convert into real money
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="privacy-text113">
              Join our waitlist to be the first to know when the app launches
            </span>
          </Fragment>
        }
      ></Navbar8>
      <CTA1
        content1={
          <Fragment>
            <span className="privacy-text114">
              <span>
                Kazanco&apos;yu kullandığınız için teşekkür ederiz. Kişisel
                bilgilerinizi ve gizlilik hakkınızı korumaya kararlıyız. Bu
                politika veya uygulamalarımız hakkında herhangi bir sorunuz veya
                endişeniz varsa, lütfen kazanco@gmail.com adresinden bizimle
                iletişime geçin.
              </span>
              <br></br>
              <br></br>
              <span>
                Bu Gizlilik Politikası, Kazanco&apos;nun (“biz”, “bizim” veya
                “bize” olarak anılacaktır) mobil uygulamamızı kullandığınızda
                veya kazanco.me web sitemizi ziyaret ettiğinizde bilgilerinizi
                nasıl topladığını, kullandığını ve koruduğunu açıklar. Ayrıca,
                Genel Veri Koruma Yönetmeliği (GDPR) dahil olmak üzere geçerli
                gizlilik yasaları kapsamındaki haklarınızı da açıklar.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-text121">1. Topladığımız Bilgiler</span>
              <br></br>
              <span>
                Bize gönüllü olarak sağladığınız kişisel bilgilerin yanı sıra
                uygulama aracılığıyla otomatik olarak toplanan bilgileri de
                toplarız.
              </span>
              <br></br>
              <br className="privacy-text125"></br>
              <span className="privacy-text126">1.1 Kişisel Bilgiler</span>
              <br className="privacy-text127"></br>
              <span className="privacy-text128">
                - İletişim Bilgileri:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>İsim, e-posta adresi.</span>
              <br></br>
              <span className="privacy-text131">
                - Hesap Bilgileri:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>Kullanıcı adı, profil tercihleri.</span>
              <br></br>
              <span className="privacy-text134">- Ödeme Bilgileri:</span>
              <span>
                {' '}
                Banka hesap bilgileri veya dijital cüzdan adresleri gibi para
                çekme işlemlerini gerçekleştirmek için gerekli ödeme bilgileri.
              </span>
              <br></br>
              <br className="privacy-text137"></br>
              <span className="privacy-text138">
                1.2 Otomatik Bilgi Toplama
              </span>
              <br></br>
              <span className="privacy-text140">
                - Kullanım Verileri:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Kullandığınız özellikler ve reklamları izleyerek geçirdiğiniz
                süre gibi uygulama ile etkileşimleriniz hakkında bilgiler.
              </span>
              <br></br>
              <span className="privacy-text143">
                - Cihaz Bilgileri:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Cihaz türü, işletim sistemi, benzersiz cihaz tanımlayıcıları.
              </span>
              <br></br>
              <span className="privacy-text146">
                - Konum Bilgileri:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Deneyiminizi özelleştirmek ve alakalı reklamlar sunmak için IP
                adresine dayalı yaklaşık konum.
              </span>
              <br></br>
              <br className="privacy-text149"></br>
              <span className="privacy-text150">
                2. Bilgilerinizi Nasıl Kullanıyoruz
              </span>
              <br></br>
              <span>Bilgilerinizi aşağıdaki amaçlar için kullanırız:</span>
              <br></br>
              <span className="privacy-text154">
                - Hizmetleri Sağlamak ve Geliştirmek:
              </span>
              <span>
                {' '}
                Deneyiminizi kişiselleştirmek, uygulamamızı korumak ve
                geliştirmek ve hesabınızı yönetmek için.
              </span>
              <br></br>
              <span className="privacy-text157">
                - Ödüller ve Ödemeler:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Reklamlar aracılığıyla kazanılan puanları işlemek ve para çekme
                işlemlerini etkinleştirmek için.
              </span>
              <br></br>
              <span className="privacy-text160">
                - İletişim:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Sorulara yanıt vermek, güncellemeler sağlamak ve yeni özellikler
                veya promosyonlar hakkında sizi bilgilendirmek için.
              </span>
              <br></br>
              <span className="privacy-text163">- Analitik:</span>
              <span>
                {' '}
                Uygulama kullanımını analiz etmek ve kullanıcı deneyimini ve
                performansını iyileştirmek için.
              </span>
              <br></br>
              <span className="privacy-text166">
                - Yasal Uyumluluk:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Yasal gerekliliklere uymak ve dolandırıcılık faaliyetlerini
                önlemek için.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-text170">
                3. Bilgilerinizin Paylaşılması
              </span>
              <br></br>
              <span>Bilgilerinizi aşağıdakilerle paylaşabiliriz:</span>
              <br></br>
              <span className="privacy-text174">- Hizmet Sağlayıcılar:</span>
              <span>
                {' '}
                Uygulamamızı yönetmemize, verileri barındırmamıza, ödemeleri
                işlememize veya reklam sunmamıza yardımcı olan üçüncü taraf
                satıcılar.
              </span>
              <br></br>
              <span className="privacy-text177">
                - İş Transferleri:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Bir birleşme, satın alma veya varlık satışı durumunda
                bilgileriniz aktarılabilir.
              </span>
              <br></br>
              <span className="privacy-text180">
                - Yasal Yükümlülükler:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Yasal yükümlülüklere uymak, yasal taleplere yanıt vermek veya
                haklarımızı korumak için bilgilerinizi ifşa edebiliriz.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-text184">
                4. GDPR Kapsamındaki Haklarınız
              </span>
              <br></br>
              <span>
                AB&apos;de bulunuyorsanız, aşağıdaki haklara sahipsiniz:
              </span>
              <br></br>
              <span className="privacy-text188">
                - Erişim:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Kişisel verilerinize erişim talep etme ve bir kopyasını alma
                hakkına sahipsiniz.
              </span>
              <br></br>
              <span className="privacy-text191">
                - Düzeltme:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Yanlış veya eksik veriler için düzeltme talep edebilirsiniz.
              </span>
              <br></br>
              <span className="privacy-text194">
                - Silme:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Belirli durumlarda verilerinizin silinmesini talep etme hakkına
                sahipsiniz.
              </span>
              <br></br>
              <span className="privacy-text197">
                - Kısıtlama:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Verilerinizin işlenmesini sınırlandırmamızı talep edebilirsiniz.
              </span>
              <br></br>
              <span className="privacy-text200">- Veri Taşınabilirliği:</span>
              <span>
                {' '}
                Verilerinizi yaygın olarak kullanılan bir formatta talep
                edebilirsiniz.
              </span>
              <br></br>
              <span className="privacy-text203">
                - İtiraz:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Pazarlama dahil olmak üzere belirli işleme faaliyetlerine itiraz
                edebilirsiniz.
              </span>
              <br></br>
              <span className="privacy-text206">
                - Onayın Geri Çekilmesi:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                Verilerinizi izne dayalı olarak işliyorsak, izninizi istediğiniz
                zaman geri çekebilirsiniz.
              </span>
              <br></br>
              <span>
                Bu haklarınızı kullanmak için lütfen kazanco@gmail.com
                adresinden bizimle iletişime geçin.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-text212">5. Veri Saklama</span>
              <br></br>
              <span>
                Yasalar daha uzun bir saklama süresi gerektirmediği sürece,
                kişisel verilerinizi yalnızca bu Gizlilik Politikasında
                belirtilen amaçları yerine getirmek için gerekli olduğu sürece
                saklarız. Verilerinize artık ihtiyacımız olmadığında, bunları
                silecek veya anonim hale getireceğiz.
              </span>
              <br></br>
              <br className="privacy-text216"></br>
              <span className="privacy-text217">
                6. Bilgilerinizin Güvenliği
              </span>
              <br className="privacy-text218"></br>
              <span>
                Kişisel verilerinizi yetkisiz erişim, ifşa, değiştirme veya
                imhaya karşı korumak için uygun teknik ve organizasyonel
                önlemleri uyguluyoruz. Ancak, hiçbir elektronik depolama yöntemi
                %100 güvenli değildir, bu nedenle mutlak güvenliği garanti
                edemeyiz.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-text222">
                7. Çerezler ve İzleme Teknolojileri
              </span>
              <br className="privacy-text223"></br>
              <span>
                Kazanco, kullanıcı deneyimini iyileştirmek ve uygulama
                performansını ölçmek için çerezleri ve benzer izleme
                teknolojilerini kullanır. Çerezler sizi tanımamıza,
                kullanımınızı izlememize ve ilgili reklamları göstermemize
                yardımcı olur. Çerezleri devre dışı bırakmak için cihaz
                ayarlarınızı değiştirebilirsiniz, ancak bu uygulama
                işlevselliğini etkileyebilir.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-text227">8. Çocukların Gizliliği</span>
              <br></br>
              <span>
                Uygulamamız 16 yaşından küçük çocuklar için tasarlanmamıştır ve
                16 yaşından küçük çocuklardan bilerek kişisel bilgi
                toplamıyoruz. Bu tür verileri topladığımızı düşünüyorsanız,
                derhal silebilmemiz için lütfen kazanco@gmail.com adresinden
                bizimle iletişime geçin.
              </span>
              <br></br>
              <br className="privacy-text231"></br>
              <span className="privacy-text232">
                9. Bu Gizlilik Politikasındaki Değişiklikler
              </span>
              <br className="privacy-text233"></br>
              <span>
                Bu Gizlilik Politikasını periyodik olarak güncelleyebiliriz.
                Güncellenen politikayı kazanco.me adresinde yayınlayarak ve en
                son revizyon tarihini belirterek önemli değişiklikleri size
                bildireceğiz. Lütfen bu politikayı düzenli olarak gözden
                geçirin.
              </span>
              <br></br>
              <br></br>
              <span className="privacy-text237">10. Bize Ulaşın</span>
              <br className="privacy-text238"></br>
              <span>
                Bu Gizlilik Politikası veya kişisel bilgileriniz hakkında
                sorularınız veya endişeleriniz varsa, lütfen bizimle iletişime
                geçin:
              </span>
              <br></br>
              <br></br>
              <span>E-posta: kazanco@gmail.com</span>
              <br></br>
              <span>Adres İstanbul, Türkiye</span>
              <br></br>
              <br></br>
              <span className="privacy-text247">
                Bu Gizlilik Politikası, uygulamalarımız hakkında size açık ve
                kapsamlı bilgi vermek için tasarlanmıştır. Kazanco&apos;yu
                kullanarak, bu Gizlilik Politikasının şartlarını kabul etmiş
                olursunuz.
              </span>
              <br className="privacy-text248"></br>
              <br></br>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="privacy-text252">Gizlilik Politikası</span>
          </Fragment>
        }
      ></CTA1>
    </div>
  )
}

export default Privacy

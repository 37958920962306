import React, { Fragment, useState } from 'react'
import { db, collection, addDoc } from './firebase';
import PropTypes from 'prop-types'
import './column.css'

const Column = (props) => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  
  const joinWaitlist = async () => {
    if (!email) {
      console.log("Please enter an email address.");
      return;
    }

    try {
      await addDoc(collection(db, "waitlist_emails"), {
        email: email,
        timestamp: new Date()
      });
      console.log("You’ve been added to the waitlist.");
      setEmail("");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div
      className={`column-column thq-section-padding thq-section-max-width ${props.rootClassName} `}
    >
      <div className="column-content">
        <h1 className="column-text1 thq-heading-1">
          {props.heading1 ?? (
            <Fragment>
              <span className="column-text4">
                Medium length hero headline goes here
              </span>
            </Fragment>
          )}
        </h1>
        <p className="column-text2 thq-body-large">
          {props.content1 ?? (
            <Fragment>
              <span className="column-text5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse varius enim in eros elementum tristique. Duis
                cursus, mi quis viverra ornare, eros dolor interdum nulla, ut
                commodo diam libero vitae erat.
              </span>
            </Fragment>
          )}
        </p>
      </div>
      <div className="column-actions">
        <input
          onChange={handleEmailChange}
          type="email"
          name="email"
          value={email}
          placeholder={props.textinputPlaceholder}
          className="column-textinput input"
        />
        <button name="joinBtn"
                className="thq-button-filled column-button"
                onClick={joinWaitlist}>
          <span className="thq-body-small">
            {props.action1 ?? (
              <Fragment>
                <span className="column-text6">Main action</span>
              </Fragment>
            )}
          </span>
        </button>
      </div>
    </div>
  )
}

Column.defaultProps = {
  heading1: undefined,
  content1: undefined,
  textinputPlaceholder: 'placeholder',
  rootClassName: '',
  action1: undefined,
}

Column.propTypes = {
  heading1: PropTypes.element,
  content1: PropTypes.element,
  textinputPlaceholder: PropTypes.string,
  rootClassName: PropTypes.string,
  action1: PropTypes.element,
}

export default Column
